import type { FetchPolicy } from '@apollo/client';
import {
  type Country,
  type Language,
  type SiteName,
  languages,
  siteConfig,
} from '@seek/melways-sites';
import {
  type GetPreviewDataQuery,
  type GetArticlesQuery,
  type GetAuthorWithArticlesQuery,
  Locale,
  type GetPagesQuery,
  type CButtonLink,
  type CTextLink,
  type Section,
  type Maybe,
  type SectionWhereInput,
  type CImageLink,
  type GetArticlesByCategoryPaginationQuery,
  type GetCategoriesWithLatestArticlesQuery,
  type BannerItemFragment,
  type FormFragment,
  type Stage,
} from '@seek/cmsu-cms-connect';
import type { ConfigStateContext } from '../client/shared/hooks/useConfig';
import {
  getPreviewQueryProps,
  hygraphQueryHeaders,
} from '@seek/cmsu-components/src/helpers/hygraphHelper';

export type SubscriptionFormType = FormFragment | null | undefined;
export type ContactUsType = BannerItemFragment | null | undefined;

export type ArticleLocalization =
  | GetArticlesQuery['articles'][number]['localizations'][number] & {
      formData?: SubscriptionFormType;
      contactUs?: ContactUsType;
    } & {
      id?: string;
    };
export type AuthorLocalization =
  | GetAuthorWithArticlesQuery['authors'][number]['localizations'][number];

export type AuthorWithArticles =
  | GetAuthorWithArticlesQuery['authors'][number]['localizations'][number] & {
      articles: GetAuthorWithArticlesQuery['articlesConnection'];
    };

export type CategoryLocalization = {
  category: GetCategoriesWithLatestArticlesQuery['categories'][number]['localizations'][number];
  articles: GetArticlesByCategoryPaginationQuery['articlesConnection'];
  formData?: SubscriptionFormType;
  contactUs?: ContactUsType;
};

/**
 * This model is used to preview an article or a page.
 */
export type PreviewDocument = {
  id: string | undefined;
  isArticle: boolean;
  title: string;
  site: string;
  slug: string;
  link: string;
  locale: string;
  sectionName: string | undefined;
  parentSectionName: string | undefined;
  stage: string | undefined;
};

export type PreviewData = {
  previewDocuments: PreviewDocument[];
  slug: string;
  isArticle: boolean;
};

type Blocks = GetPagesQuery['pages'][0]['containers'][0]['blocks'];

type BlockItems = Blocks[0]['items'];

/**
 * Checks if the content is Not Available.
 * Added more line, remove this
 * If the content value is dash (-), it means the content isn't available.
 * @param text
 */
export const isContentNA = (text: any) => {
  if (!text) {
    return true;
  }

  return text.trim() === '-';
};

/**
 * The function `buildCandidateUrl` returns a URL based on the provided configuration and preview
 * document.
 * @param {ConfigStateContext} config
 * @param {PreviewDocument} previewDoc
 * @returns The function `buildCandidateUrl` returns a string representing a URL based on the provided
 * `config` and `previewDoc` parameters.
 */
const buildCandidateUrl = (
  config: ConfigStateContext,
  previewDoc: PreviewDocument,
): string => {
  const site = previewDoc.site.toLowerCase() as SiteName;
  const currentSiteConfig = siteConfig[site];

  if (config.isStaging) {
    return `https://${currentSiteConfig.stagingHost}`;
  }

  if (config.isProduction) {
    return `https://${currentSiteConfig.host}`;
  }

  return `http://${currentSiteConfig.host}.local:8000`;
};

/**
 * Convert a URL slug into a Hygraph-friendly SARA/STAR slug.
 * On Hygraph these slugs are prefixed with the section name to ensure they are unique.
 * eg., 'sara-home' or 'starawards-entry-tips'
 * @param sections
 * @param slug
 * @param {Country} country
 */
export const transformAwardSiteSlug = (
  sections: string[],
  slug: string | undefined | null,
  country: Country,
) => {
  if (!slug) return;

  // List of sections that need their page slugs to be prefixed with the section name
  const exceptionSections = ['sara', 'starawards', 'starawards-test'];

  // Match the slug directly with the possible exceptionSection,
  // if it matches, return the slug with '-home' appended
  const matchedSlug = exceptionSections.filter(
    (s) => s === slug.replace('-test', ''),
  )[0];

  if (matchedSlug) {
    return `${matchedSlug}-home${country && country === 'nz' ? '-nz' : ''}`;
  }

  // Match the section directly with the possible exceptionSection,
  // if it matches, return the slug with the section name prefixed
  if (sections.length > 0) {
    const matchedSection = exceptionSections.filter(
      (s) => s === sections[0].replace('-test', ''),
    )[0];

    if (matchedSection) {
      return `${matchedSection}-${slug}${
        country && country === 'nz' ? '-nz' : ''
      }`;
    }
  }

  // If the slug is not an exception, return the slug as is
  return slug;
};

export const trimAwardSiteSlug = (slug: string) =>
  slug.replace(/(sara-|starawards-)|(-nz)/g, '');

/**
 * It returns a preview link for article and page
 * @param config
 * @param previewDoc
 */
export function getPreviewDocumentLink(
  config: ConfigStateContext,
  previewDoc: PreviewDocument,
) {
  const locale = previewDoc.locale.toLowerCase();
  const site = previewDoc.site.toLowerCase();
  const isCandidate = site.indexOf('candidate') !== -1;
  const isSection = previewDoc.sectionName;

  const anzStagingUrls: Record<string, string> = {
    au: 'https://talent.staging.seek.com.au',
    nz: 'https://talent.staging.seek.co.nz',
  };

  const anzProdUrls: Record<string, string> = {
    au: 'https://talent.seek.com.au',
    nz: 'https://talent.seek.co.nz',
  };

  // it must has a section name if it is article
  if (previewDoc?.isArticle && !isSection && !isCandidate) {
    return null;
  }

  // if this is article
  if (previewDoc.isArticle && isContentNA(previewDoc.title)) {
    // if the article content has dash '-', it isn't supported
    return null;
  }

  const localeChars = locale.slice(-2);
  const siteChars = site.slice(-2);
  const isDefault = locale === 'default';
  const aboutPage = 'about'; // about page is a special case

  // if it isn't default and country code not matching, link not valid
  if (!isDefault && localeChars !== siteChars) {
    return null;
  }

  // if it isn't staging or
  let link = 'http://{COUNTRY}.employer.seek.com.local:8000';

  if (config.isStaging) {
    link =
      anzStagingUrls[siteChars] ||
      'https://{COUNTRY}.employer.staging.seek.com';
  }

  if (config.isProduction) {
    link = anzProdUrls[siteChars] || 'https://{COUNTRY}.employer.seek.com';
  }

  // if it is a candidate page, it served from SKL domain
  if (isCandidate) {
    link = buildCandidateUrl(config, previewDoc);
  }

  link = link.replace('{COUNTRY}', siteChars);

  const langCondition = !isDefault && !locale.startsWith('en_');
  const lang = langCondition ? `/${locale.slice(0, 2)}` : '';

  // if slug is a full path (starts with /)
  if (previewDoc.slug.startsWith('/'))
    return `${link}${lang}${previewDoc.slug}`;

  // if it is article and hirer and candidate articles supported
  if (previewDoc.isArticle) {
    // candidate articles that is draft and section is career-advice
    if (
      previewDoc?.stage === 'DRAFT' &&
      previewDoc?.sectionName === 'career-advice'
    ) {
      return `${link}${lang}/career-advice/article-preview/${previewDoc.id}`;
    }
    // both hirer and candidate to follow same structure
    return `${link}${lang}/${
      previewDoc.parentSectionName ? `${previewDoc.parentSectionName}/` : ''
    }${previewDoc.sectionName}/article/${previewDoc.slug}`;
  }

  const isSectionMatchSlug =
    isSection &&
    previewDoc.sectionName &&
    previewDoc.sectionName === previewDoc.slug;

  // this is page and if it is hirer page
  if (!isCandidate) {
    // about page is a section and shouldn't have entry page
    if (previewDoc.slug === aboutPage) {
      return null;
    }

    const resolvePageLink = () => {
      // if there is a section name and matches with slug such as 'hiring-advice'
      if (isSectionMatchSlug) return previewDoc.slug;

      return !previewDoc.sectionName
        ? `page/${previewDoc.slug}`
        : `${
            previewDoc.parentSectionName
              ? `${previewDoc.parentSectionName}/`
              : ''
          }${previewDoc.sectionName}/${previewDoc.slug}`;
    };

    return `${link}${lang}/${resolvePageLink()}`;
  }

  // last case is candidate page
  // https://th.jobsdb.seek.com/page/{slug}
  // the about page in Candidate is a special case
  if (previewDoc.slug.toLowerCase() === 'about') {
    return `${link}${lang}/${previewDoc.slug}`;
  }

  // If the site is a Candidate site and has a section
  if (previewDoc.sectionName) {
    // If the section name matches the slug, don't append the slug
    const linkPage = `${
      previewDoc.parentSectionName ? `${previewDoc.parentSectionName}/` : ''
    }${previewDoc.sectionName}/${
      previewDoc.slug === previewDoc.sectionName ? '' : previewDoc.slug
    }`;
    return `${link}${lang}/${linkPage}`;
  }

  // if there is a section name and matches with slug, return the link without `page`
  const pagePrefix = !isSectionMatchSlug ? 'page/' : '';

  return `${link}${lang}/${pagePrefix}${previewDoc.slug}`;
}

/**
 * It returns combine article and page data.
 * @param config
 * @param data
 */
export const getPreviewDocumentData = (
  config: ConfigStateContext,
  data: GetPreviewDataQuery,
): PreviewData => {
  const documents: PreviewDocument[] = [];
  let documentSlug = '';
  let isArticle = false;
  // get the articles for each site if there is any
  data.article?.sites.forEach((site) => {
    data.article?.localizations?.forEach((article) => {
      const { title, slug, locale } = article;

      const previewDoc: PreviewDocument = {
        id: data.article?.id,
        isArticle: true,
        title,
        slug,
        locale,
        site: site?.name,
        link: '',
        sectionName: article.category?.relatedCategorySection?.sectionName,
        parentSectionName:
          article.category?.relatedCategorySection?.parentSection?.sectionName,
        stage: data.article?.stage,
      };

      const link = getPreviewDocumentLink(config, previewDoc);

      if (link) {
        isArticle = true;
        documentSlug = slug;
        previewDoc.link = link;
        documents.push(previewDoc);
      }
    });
  });

  // get the pages for each site if there is any
  data.page?.sites.forEach((site) => {
    data.page?.localizations?.forEach((page) => {
      const { title, slug, locale } = page;
      const id = data.page?.id;
      const stage = data.page?.stage;

      const previewDoc: PreviewDocument = {
        id,
        isArticle: false,
        title,
        slug,
        locale,
        site: site?.name,
        link: '',
        sectionName: data.page?.relatedPageSection?.sectionName,
        parentSectionName:
          data.page?.relatedPageSection?.parentSection?.sectionName,
        stage,
      };

      if (slug.includes('sara-') || slug.includes('star-')) {
        previewDoc.slug = trimAwardSiteSlug(slug);
      }

      const link = getPreviewDocumentLink(config, previewDoc);

      if (link) {
        documentSlug = previewDoc.slug;
        previewDoc.link = link;
        documents.push(previewDoc);
      }
    });
  });

  // default should come first
  const docsSorted: PreviewDocument[] = documents.sort((a, b) => {
    if (a.locale === 'default' && b.locale !== 'default') {
      return -1;
    } else if (a.locale !== 'default' && b.locale === 'default') {
      return 1;
    }
    return 0;
  });

  const docsFitered = filterBySiteAndLocale(docsSorted);
  // group by site value
  const docsGroupBy = docsFitered.reduce(
    (accumulator: { [key: string]: PreviewDocument[] }, current) => {
      if (!accumulator[current.site]) {
        accumulator[current.site] = [];
      }
      accumulator[current.site].push(current);
      return accumulator;
    },
    {},
  );

  const singleArray: PreviewDocument[] = Object.values(
    docsGroupBy,
  ).flat() as PreviewDocument[];

  // remove duplicated links
  const uniqueArray = singleArray.reduce(
    (accumulator: PreviewDocument[], current: PreviewDocument) => {
      if (!accumulator.some((item) => item.link === current.link)) {
        accumulator.push(current);
      }
      return accumulator;
    },
    [] as PreviewDocument[],
  );

  return {
    previewDocuments: uniqueArray as PreviewDocument[],
    slug: documentSlug,
    isArticle,
  };
};

/**
 * It returns an article from HyGraph response
 * @param data
 * @param hygraphLocale
 */
export const getArticleFromData = (
  data: GetArticlesQuery,
  hygraphLocale: Locale,
): (ArticleLocalization & { id: string }) | null => {
  const localizations = data.articles[0]?.localizations;
  const id = data.articles[0]?.id;
  const locale = localizations.filter((o) => hygraphLocale === o.locale);

  // if there is any country locale exists such as th_TH, id_ID or en_PH, return this locale
  if (locale && locale.length && !isContentNA(locale[0]?.content?.text)) {
    return {
      ...locale[0],
      id,
      category: getCategoryByLocale(hygraphLocale, localizations),
      featureImage: getFeatureImageByLocale(hygraphLocale, localizations),
      seo: getSeoByLocale(hygraphLocale, localizations),
    };
  }

  // if it is en locale and specific en locale not exist, it will return default locale
  if (supportedDefaultLocales.includes(hygraphLocale)) {
    const defaultLocale = data.articles[0]?.localizations.filter(
      (o) => Locale.Default === o.locale,
    );

    // if there is any default locale exists, return this
    if (
      defaultLocale &&
      defaultLocale.length &&
      !isContentNA(defaultLocale[0]?.content?.text)
    ) {
      return { id, ...defaultLocale[0] };
    }
  }

  // if nothing has been found, return null
  return null;
};

/**
 * It returns an author from HyGraph response
 * @param data
 * @param hygraphLocale
 */
export const getAuthorWithArticlesFromData = (
  data: GetAuthorWithArticlesQuery,
  hygraphLocale: Locale,
): AuthorWithArticles | null => {
  const localizations = data.authors[0]?.localizations;
  const locale = localizations.filter((o) => hygraphLocale === o.locale);

  // if there is any country locale exists such as th_TH, id_ID or en_PH, return this locale
  if (locale && locale.length && !isContentNA(locale[0]?.name)) {
    return {
      ...locale[0],
      articles: {
        ...data.articlesConnection,
      },
    };
  }

  // if it is en locale and specific en locale not exist, it will return default locale
  if (supportedDefaultLocales.includes(hygraphLocale)) {
    const defaultLocale = data.authors[0]?.localizations.filter(
      (o) => Locale.Default === o.locale,
    );

    // if there is any default locale exists, return this
    if (
      defaultLocale &&
      defaultLocale.length &&
      !isContentNA(defaultLocale[0]?.name)
    ) {
      return { ...defaultLocale[0], articles: data.articlesConnection };
    }
  }

  // if nothing has been found, return null
  return null;
};

const getDefaultLocalization = (localizations: ArticleLocalization[]) =>
  localizations.filter(
    (l: ArticleLocalization) => l.locale === Locale.Default,
  )[0];

const getCategoryByLocale = (
  locale: Locale,
  localizations: ArticleLocalization[],
): ArticleLocalization['category'] => {
  const { category } = getDefaultLocalization(localizations);
  const localeCategory = localizations.filter(
    (l: ArticleLocalization) => l.locale === locale,
  )[0]?.category;
  if (!localeCategory && supportedDefaultLocales.includes(locale)) {
    return category;
  }
  return localeCategory;
};

const getFeatureImageByLocale = (
  locale: Locale,
  localizations: ArticleLocalization[],
): ArticleLocalization['featureImage'] => {
  const { featureImage } = getDefaultLocalization(localizations);
  const localeFeatureImage = localizations.filter(
    (l: ArticleLocalization) => l.locale === locale,
  )[0]?.featureImage;
  return !localeFeatureImage?.length ? featureImage : localeFeatureImage;
};

const getSeoByLocale = (
  locale: Locale,
  localizations: ArticleLocalization[],
): ArticleLocalization['seo'] => {
  const { seo } = getDefaultLocalization(localizations);
  let localeSeo = localizations.filter(
    (l: ArticleLocalization) => l.locale === locale,
  )[0]?.seo;
  if (!localeSeo?.image) {
    localeSeo = { ...localeSeo, image: seo?.image };
  }
  return !localeSeo ? seo : localeSeo;
};

export const supportedDefaultLocales: string[] = [
  'default',
  'en_PH', // English (Philippines) Candidate
  'en_MY', // English (Malaysia) Candidate
  'en_HK', // English (Hong Kong)
  'en_PH', // English (Philippines)
  'en_SG', // English (Singapore)
  'en_ID', // English (Indonesia)
  'en_TH', // Thai (English)
  'en_AU', // English (Australia)
  'en_NZ', // English (New Zealand)
];

/**
 * Filters an array of block items to return only blocks that do not have default locale.
 * @param blockItems
 */
export const filterOutDefaultLocaleItems = (
  blockItems: BlockItems,
): BlockItems =>
  blockItems.filter((item) => {
    if ('locale' in item && item.locale === Locale.Default) {
      return false;
    }

    if (
      item.__typename === 'Video' &&
      item.source &&
      'locale' in item.source &&
      item.source.locale === Locale.Default
    ) {
      return false;
    }
    if (item.__typename === 'CTestimonialGroup') {
      return item.testimonials.some((t) => t.locale !== Locale.Default);
    }

    return true;
  });

/**
 * The function filters out block items contents that are not default locale.
 * @param blockItems
 */
export const filterOutDefaultLocaleContents = (
  blockItems: BlockItems,
): BlockItems =>
  blockItems.map((item) => {
    if (item.__typename === 'AccordionGroup') {
      return {
        ...item,
        accordions: item.accordions.filter((a) => a.locale !== Locale.Default),
      };
    }

    if (item.__typename === 'CCardGroup') {
      return {
        ...item,
        cards: item.cards.filter(
          (card) =>
            card.heading?.locale !== Locale.Default &&
            card.paragraph?.locale !== Locale.Default &&
            card.image?.locale !== Locale.Default,
        ),
      };
    }

    if (item.__typename === 'CGallery') {
      return {
        ...item,
        assets: item.assets.filter((a) => a.locale !== Locale.Default),
      };
    }

    if (item.__typename === 'CImageBlockGroup') {
      return {
        ...item,
        items: item.items.filter(
          (i) =>
            i.image?.locale !== Locale.Default &&
            i.heading?.locale !== Locale.Default &&
            i.paragraph?.locale !== Locale.Default,
        ),
      };
    }

    if (item.__typename === 'TabGroup') {
      return {
        ...item,
        tabs: item.tabs.filter((t) => t.locale !== Locale.Default),
      };
    }

    if (
      item.__typename === 'CBanner' &&
      item.banner_items &&
      item.banner_items.__typename === 'CBannerInline'
    ) {
      return {
        ...item,
        banner_items: {
          ...item.banner_items,
          image:
            item.banner_items?.image?.locale !== Locale.Default
              ? item.banner_items?.image
              : undefined,
          heading:
            item.banner_items?.heading?.locale !== Locale.Default
              ? item.banner_items?.heading
              : undefined,
          actionGroup: item.banner_items?.actionGroup
            ? {
                ...item.banner_items?.actionGroup,
                actions: item.banner_items?.actionGroup.actions.filter(
                  (a) => !isLinkItemWithDefaultLocale(a),
                ),
              }
            : undefined,
          inlineDivider: item.banner_items?.inlineDivider
            ? {
                ...item.banner_items?.inlineDivider,
                actions: item.banner_items?.inlineDivider.actions.filter(
                  (a) => !isLinkItemWithDefaultLocale(a),
                ),
              }
            : undefined,
          paragraph:
            item.banner_items?.paragraph?.locale !== Locale.Default
              ? item.banner_items?.paragraph
              : undefined,
        },
      };
    }
    if (
      item.__typename === 'CBanner' &&
      item.banner_items &&
      item.banner_items.__typename === 'CBannerArticle'
    ) {
      return {
        ...item,
        banner_items: {
          ...item.banner_items,
          name:
            item.banner_items?.locale !== Locale.Default
              ? item.banner_items?.name
              : undefined,
        },
      };
    }

    if (item.__typename === 'CActionGroup') {
      return {
        ...item,
        actions: item.actions.filter((a) => !isLinkItemWithDefaultLocale(a)),
      };
    }

    if (item.__typename === 'CIconBlockGroup') {
      return {
        ...item,
        items: item.items.filter(
          (i) =>
            i.heading?.locale !== Locale.Default &&
            i.paragraph?.locale !== Locale.Default,
        ),
      };
    }

    if (
      item.__typename === 'CCustomComponent' &&
      item.data?.__typename === 'WebForm'
    ) {
      return {
        ...item,
        data:
          item?.data?.locale !== Locale.Default ? { ...item.data } : undefined,
      };
    }

    if (
      item.__typename === 'CCustomComponent' &&
      item.data?.__typename === 'CForm'
    ) {
      return {
        ...item,
        data:
          item?.data?.form?.locale !== Locale.Default
            ? { ...item.data }
            : undefined,
      };
    }

    if (item.__typename === 'CAlert') {
      return {
        ...item,
        paragraph: {
          ...item.paragraph,
          Paragraph_text: {
            raw:
              item.paragraph?.locale !== Locale.Default
                ? item.paragraph?.Paragraph_text?.raw
                : undefined,
          },
        },
      };
    }
    if (item.__typename === 'CTestimonialGroup') {
      return {
        ...item,
        testimonials: item.testimonials.filter(
          (t) =>
            t.locale !== Locale.Default &&
            t.quote?.length !== 0 &&
            t.author?.length !== 0 &&
            t.company?.length !== 0,
          // filter out empty quotes, authors and companies by checking the length to avoid showing empty testimonial card
        ),
      };
    }
    return item;
  });

/**
 * The function keeps block items from an array that have content based on their type.
 * @param blockItems
 */
export const keepBlockItemsThatHaveContent = (
  blockItems: BlockItems,
): BlockItems =>
  blockItems.filter((item) => {
    if (item.__typename === 'AccordionGroup') {
      return item.accordions.length !== 0;
    }

    if (item.__typename === 'CImageBlockGroup') {
      return item.items.length !== 0;
    }

    if (item.__typename === 'CCardGroup') {
      return item.cards.length !== 0;
    }

    if (item.__typename === 'TabGroup') {
      return item.tabs.length !== 0;
    }

    if (item.__typename === 'CIconBlockGroup') {
      return item.items.length !== 0;
    }

    if (item.__typename === 'CActionGroup') {
      return item.actions.length !== 0;
    }

    if (item.__typename === 'CBanner') {
      if (item.banner_items?.__typename === 'CBannerInline') {
        return item.banner_items?.image !== undefined;
      }
    }

    if (item.__typename === 'CBanner') {
      if (item.banner_items?.__typename === 'CBannerArticle') {
        return item.banner_items?.name !== undefined;
      }
    }

    if (item.__typename === 'CCustomComponent') {
      return item.data?.__typename !== undefined;
    }

    if (item.__typename === 'CAlert') {
      return item.paragraph?.Paragraph_text?.raw !== undefined;
    }
    if (item.__typename === 'CGallery') {
      return item.assets.length !== 0;
    }

    return true;
  });

/**
 * The function filters out block items that do not have default locale and have no content.
 * @param blockItems
 */
export const filterBlockItemsByLocale = (blockItems: BlockItems): BlockItems =>
  keepBlockItemsThatHaveContent(
    filterOutDefaultLocaleContents(filterOutDefaultLocaleItems(blockItems)),
  );

/**
 * This function removes blocks that have an empty items array property.
 * @param blocks
 */
export const removeBlocksThatHaveHaveNoItems = (blocks: Blocks): Blocks =>
  blocks.filter((block) => block.items.length !== 0);

/**
 * The function formats blocks and removes blocks that have an empty items array property.
 * @param blocks
 */
export const formatBlocksForRendering = (blocks: Blocks): Blocks =>
  removeBlocksThatHaveHaveNoItems(
    blocks.map((b) => {
      const formattedItems = !b.skipDefaultLocale
        ? b.items
        : filterBlockItemsByLocale(b.items);

      return {
        ...b,
        items: formattedItems,
      };
    }),
  );

/**
 * Recursively creates the query for sections
 * NOTE: routes needs to be reverse with the child in the first order
 * @param routes
 * @returns
 */
export const getSectionQuery: (routes?: string[]) => SectionWhereInput = (
  routes,
) => {
  if (!routes || routes.length === 0) {
    return {};
  }

  const query: SectionWhereInput = {
    sectionName: routes[0],
  };

  if (routes.length > 1) {
    query.parentSection = getSectionQuery(routes.slice(1));
  }

  return query;
};

/**
 * Recursively gets the name of the sections from the loader's payload
 * @param section
 */
export const getAllSections: (
  section: Maybe<Section> | undefined,
  path?: string,
) => string[] = (section, path = '') => {
  if (!section) {
    return [];
  }

  let nestedSections: string[] = [];

  for (const key in section) {
    if (section.hasOwnProperty(key)) {
      const currentPath = path ? `${path}.${key}` : key;
      if (key === 'parentSection') {
        nestedSections = nestedSections.concat(
          getAllSections(section.parentSection, currentPath),
        );
      } else if (key === 'sectionName') {
        nestedSections.push(section.sectionName);
      }
    }
  }

  return nestedSections;
};

export const isLinkItemWithDefaultLocale = (item: {
  __typename?: string;
}): boolean => {
  const linkTypes = ['CButtonLink', 'CTextLink', 'CImageLink'];
  return (
    linkTypes.includes(item.__typename ?? '') &&
    (item as CButtonLink | CTextLink | CImageLink).locale === Locale.Default
  );
};

/**
  slugName indicates the type of slug and is a separator between section and slug (e.g. category)
  e.g. hiring-advice/category/attracting-candidates
  In this case, attracting-candidates is a slug which is a category page, and hiring-advice is the section for the category
**/
export const getRouteInfo = (routeArray: string[], slugName?: string) => {
  const routes = routeArray.filter(
    (r) => r !== '' && !languages.includes(r as Language),
  );

  // Note: This implementation implies that we cannot create a page with just a number as its slug.
  // Any number at the end of the route will be interpreted as a page number and removed from the slug.
  // This was added after https://myseek.atlassian.net/browse/WP-3764
  // to cater for pages that have pagination :slug/:pagenumber

  // Check if the last element is a number (potential page number)
  const lastElement = routes[routes.length - 1];
  let pageNo = !isNaN(Number(lastElement)) ? Number(lastElement) : undefined;

  const EXTREME_PAGE_VALUE = 2000;
  // Remove the page number from routes if it exists and is less than 2000.
  // If we need to support more than 2000 pages, we need to update this number
  // This let's us use page slugs with relevant yearly dates. eg., 2023, 2024
  if (pageNo !== undefined && pageNo < EXTREME_PAGE_VALUE) {
    routes.pop();
  } else {
    pageNo = undefined;
  }

  // This should only be called after language, pageumber and slug has been pop/remove from routes
  const generateSlugWithPath = (slug: string | undefined) =>
    `/`.concat(routes.join('/')).concat(`/${slug}`).replace('//', '/');

  /*
    if slugName exists (e.g. category), we need to get the section which is before slugName and we want to pop the slug from route
  */
  if (slugName) {
    const slugNameIndex = routes.indexOf(slugName);
    if (slugNameIndex > -1) {
      const sections = routes.slice(0, slugNameIndex);
      const slug = routes.pop();
      const slugWithPath = generateSlugWithPath(slug);

      return { sections, slug, slugWithPath, pageNo };
    }
    return {
      sections: [],
      slug: undefined,
      slugWithPath: undefined,
      pageNo: undefined,
    };
  }

  const slug = routes.pop();
  const sections = routes;

  const slugWithPath = generateSlugWithPath(slug);

  return { sections, slug, slugWithPath, pageNo };
};

export const isEverySectionsMatchesSectionsFromRoute = (
  sections: string[],
  sectionsFromRoute: string[],
): boolean => sections.every((section) => sectionsFromRoute.includes(section));

// Function to filter array based on 'site' and 'locale'
export const filterBySiteAndLocale = (documents: PreviewDocument[]) => {
  const grouped = new Map();

  // Group by 'site'
  documents.forEach((item) => {
    if (!grouped.has(item.site)) {
      grouped.set(item.site, []);
    }
    grouped.get(item.site).push(item);
  });

  // Filter each group
  const result: PreviewDocument[] = [];
  grouped.forEach((items) => {
    const nonDefaultLocale = items.some((item: PreviewDocument) =>
      item.locale.startsWith('en_'),
    );
    if (nonDefaultLocale) {
      const nonDefault = items.filter(
        (item: PreviewDocument) => item.locale !== 'default',
      );
      result.push(...nonDefault);
    } else {
      result.push(...items);
    }
  });

  return result;
};

export const isSlugMatchesLastSection = (
  sections: string[],
  slug: string | undefined,
) => sections.length >= 1 && sections[sections.length - 1] === slug;

export interface PreviewProps {
  stage: Stage;
  queryOptions: {
    fetchPolicy: FetchPolicy;
    context?: {
      headers: object;
    };
  };
}

export const getPreviewProps = (isPreview?: boolean): PreviewProps => {
  const { stage, fetchPolicy, previewHeaders } =
    getPreviewQueryProps(isPreview);
  const props = {
    stage,
    queryOptions: {
      fetchPolicy,
    },
  };
  return isPreview
    ? {
        ...props,
        queryOptions: {
          ...props.queryOptions,
          context: {
            headers: {
              ...hygraphQueryHeaders,
              ...previewHeaders,
            },
          },
        },
      }
    : props;
};
